import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom'

export default function Signup() {
  let navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [pwd, setPwd] = useState('')
  const [cpwd, setCpwd] = useState('')
  const [country, setCountry] = useState('')
  const [code, setCode] = useState('')
  const [phone, setPhone] = useState('')
  const [cur, setCur] = useState('$')
  const [navState, setNavState] = useState('hide')
  const [c1, setC1] = useState('')
  const [c2, setC2] = useState('')
  const [c3, setC3] = useState('')
  const [c4, setC4] = useState('')
  const [c5, setC5] = useState('')
  const [vcode, setVcode] = useState('')
  const [step, setStep] = useState(1)
  const [countries, setCountries] = useState([])
  useEffect(() => {
    setVcode(c1 + '' + c2 + '' + c3 + '' + c4 + '' + c5)
  }, [c1, c2, c3, c4, c5])
  const gotoNextField = (n) => {
    if (`c${n}]` !== '') {
      document.querySelector(`[name=c${n}]`)?.focus()
    }
  }
  const register = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=register'}`,
      withCredentials: false,
      data: {
        email: email,
        name: name,
        pwd: pwd,
        cpwd: cpwd,
        country: country,
        code: code,
        phone: phone,
        cur: cur,
        vcode: vcode,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        code: localStorage.getItem('regcode'),
      },
    })
      .then((response) => {
        Swal.fire(
          'REGISTRATION',
          response?.data?.msg,
          response?.data?.type === 'success' ? 'success' : 'warning',
        )
        if (response?.data?.type === 'success') {
          localStorage.setItem('secrete', response?.data?.secrete)
          navigate('/office/dashboard')
        }
      })
      .catch((error) => console.error(error))
  }
  function sendcode() {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=regcode'}`,
      withCredentials: false,
      data: { email: email, name: name },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        if (response?.data?.includes('success')) {
          Swal.fire('Code sent to Mail')
          if (response.data?.includes('-')) {
            localStorage.setItem('regcode', response.data.split('-')[1])
            setStep(3)
          }
        } else {
          Swal.fire(response?.data)
        }
      })
      .catch((error) => console.error(error))
  }
  const fixcode = (e) => {
    let c = countries.filter((i) => i.name === e.target.value)
    document.querySelector('[name=code]').value = c[0].dial_code
  }
  const NextStep = (e) => {
    e.preventDefault()
    if (step === 1) {
      if (
        document.querySelector('[name=name]').value !== '' &&
        document.querySelector('[name=email]').value !== '' &&
        document.querySelector('[name=pwd]').value !== '' &&
        document.querySelector('[name=cpwd]').value !== ''
      ) {
        if (
          document.querySelector('[name=pwd]').value ===
          document.querySelector('[name=cpwd]').value
        ) {
          setStep(step + 1)
        } else {
          Swal.fire('Passwords do not match')
        }
      } else {
        Swal.fire('Fill all Form Fields')
      }
    } else if (step === 2) {
      if (
        document.querySelector('[name=country]').value !== '' &&
        document.querySelector('[name=code]').value !== '' &&
        document.querySelector('[name=phone]').value !== '' &&
        document.querySelector('[name=cur]').value !== ''
      ) {
        sendcode()
      } else {
        Swal.fire('Fill all Form Fields')
      }
    }
  }
  useEffect(() => {
    axios({
      method: 'get',
      url: '/asset/js/countries.json',
      withCredentials: false,
    })
      .then((response) => setCountries(response.data))
      .catch((error) => console.error(error))
  }, [])

  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 px-5">
        <form
          onSubmit={(e) => register(e)}
          method="post"
          className="my-10 rounded-lg shadow-lg mt-[100px] lg:w-[50%] bg-slate-800 w-full px-5 py-10 flex flex-col gap-3 mx-auto"
          action="/signup"
        >
          <div className="flex gap-2 text-xs justify-end text-">
            <span
              className={`rounded-full border-2 p-1${
                step === 1 ? ' bg-slate-500' : ''
              }`}
            >
              1
            </span>
            <span
              className={`rounded-full border-2 p-1${
                step === 2 ? ' bg-slate-500' : ''
              }`}
            >
              2
            </span>

            <span
              className={`rounded-full border-2 p-1${
                step === 3 ? ' bg-slate-500' : ''
              }`}
            >
              3
            </span>
          </div>
          <h2 className="text-2xl mx-auto text-center font-bold uppercase">
            Create Account
          </h2>
          <p className="text-center">
            It only takes a couple of minutes to create an account. Get started
            by entering your email address and a memorable password.
          </p>
          {step === 1 && (
            <>
              <input
                required
                onChange={(e) => setName(e.target.value)}
                d-key="name"
                key="name"
                name="name"
                placeholder="Name"
                className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
              />
              <input
                onChange={(e) => setEmail(e.target.value)}
                required
                key="email"
                name="email"
                placeholder="Email"
                type="email"
                className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
              />
              <input
                onChange={(e) => setPwd(e.target.value)}
                required
                type="password"
                key="password"
                name="pwd"
                placeholder="Password"
                className="bg-slate-800 border-white text-white w-full border-2 rounded-lg py-2 px-1"
              />
              <input
                onChange={(e) => setCpwd(e.target.value)}
                required
                type="password"
                key="cpassword"
                name="cpwd"
                placeholder="Confirm Password"
                className="bg-slate-800 border-white text-white w-full border-2 rounded-lg py-2 px-1"
              />
              <button
                onClick={(e) => NextStep(e)}
                key="button"
                type="button"
                className="rounded-lg px-2 py-3 bg-slate-500 text-white font-semibold text-lg text-center mx-auto w-full"
              >
                Continue
              </button>
            </>
          )}
          {step === 2 && (
            <>
              <select
                key="country"
                name="country"
                placeholder="Country"
                className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
                onChange={(e) => {
                  fixcode(e)
                  setCountry(e.target.value)
                }}
              >
                <option value="">Select Country</option>
                {countries.map((c, k) => (
                  <option key={k} value={c.name}>
                    {c.name}
                  </option>
                ))}{' '}
              </select>
              <div className="flex">
                {' '}
                <input
                  onChange={(e) => setCode(e.target.value)}
                  name="code"
                  key="code"
                  className="w-[30%] bg-slate-800 border-white text-white w-full border-2 rounded-l-lg py-2 px-1"
                  readOnly
                  defaultvalue="+1"
                />
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  key="phone"
                  name="phone"
                  placeholder="Phone"
                  className="w-[70%] bg-slate-800 border-white text-white  w-full border-2 rounded-r-lg py-2 px-1"
                />
              </div>
              <select
                onChange={(e) => setCur(e.target.value)}
                key="cur"
                name="cur"
                placeholder="Currency"
                className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
              >
                <option value="$">USD</option>
                <option value="€">EUR</option>
                <option value="£">GBP</option>
              </select>
              {/* <button
                onClick={(e) => NextStep(e)}
                key="button"
                type="button"
                className="rounded-lg px-2 py-3 bg-slate-500 text-white font-semibold text-lg text-center mx-auto w-full"
              >
                Continue
              </button> */}
              <button
                key="submit"
                type="submit"
                className="rounded-lg px-2 py-3 bg-slate-500 text-white font-semibold text-lg text-center mx-auto w-full"
              >
                REGISTER
              </button>
            </>
          )}
          {step === 3 && (
            <>
              <h2 className="text-2xl mx-auto">ENTER CODE SENT TO MAIL</h2>
              <div className="flex gap-3 mx-auto text-center">
                {' '}
                <input
                  onChange={(e) => {
                    setC1(e.target.value)
                    if (e.target.value !== '') {
                      gotoNextField(2)
                    }
                  }}
                  name="c1"
                  key="c1"
                  maxLength={1}
                  className="text-center w-10 bg-slate-800 border-white text-white w-full border-2 rounded-md py-2 px-1"
                />
                <input
                  onChange={(e) => {
                    setC2(e.target.value)
                    if (e.target.value !== '') {
                      gotoNextField(3)
                    }
                  }}
                  name="c2"
                  key="c2"
                  maxLength={1}
                  className="text-center w-10 bg-slate-800 border-white text-white w-full border-2 rounded-md py-2 px-1"
                />
                <input
                  onChange={(e) => {
                    setC3(e.target.value)
                    if (e.target.value !== '') {
                      gotoNextField(4)
                    }
                  }}
                  name="c3"
                  key="c3"
                  maxLength={1}
                  className="text-center w-10 bg-slate-800 border-white text-white w-full border-2 rounded-md py-2 px-1"
                />
                <input
                  onChange={(e) => {
                    setC4(e.target.value)
                    if (e.target.value !== '') {
                      gotoNextField(5)
                    }
                  }}
                  name="c4"
                  key="c4"
                  maxLength={1}
                  className="text-center w-10 bg-slate-800 border-white text-white w-full border-2 rounded-md py-2 px-1"
                />
                <input
                  onChange={(e) => setC5(e.target.value)}
                  name="c5"
                  key="c5"
                  maxLength={1}
                  className="text-center w-10 bg-slate-800 border-white text-white w-full border-2 rounded-md py-2 px-1"
                />
              </div>
              <button
                onClick={(e) => sendcode()}
                key="sendbutton"
                type="button"
                className="rounded-lg px-1 py-2 bg-slate-100 text-black font-semibold text-lg text-center mx-auto w-fit"
              >
                RESEND CODE
              </button>
              <button
                key="submit"
                type="submit"
                className="rounded-lg px-2 py-3 bg-slate-500 text-white font-semibold text-lg text-center mx-auto w-full"
              >
                REGISTER
              </button>
            </>
          )}
          <div className="text-center">
            Already have an account?{' '}
            <Link to="/account/signin" className="text-slate-200">
              Login
            </Link>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  )
}
